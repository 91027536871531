import type { CreateOrderResponse } from '@wix/ambassador-payment-paybutton-web/http';
import { BaseHttpService } from '../BaseHttpService';
import { ORDER_API_PATH } from './constants';

export class OrderService extends BaseHttpService {
  async create(productId: string) {
    const { data } = await this.httpClient.post<CreateOrderResponse>(
      ORDER_API_PATH,
      { productId },
      this.headers,
    );

    return data;
  }
}
