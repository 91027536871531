import { IHttpClient } from '@wix/yoshi-flow-editor';

type HttpHeaders = {
  headers: { Authorization: string };
};

export class BaseHttpService {
  protected headers: HttpHeaders = {
    headers: { Authorization: '' },
  };

  constructor(
    protected readonly httpClient: IHttpClient,
    private readonly instance: string,
  ) {
    this.headers.headers.Authorization = this.instance;
  }
}
