import type { Factory } from '@wix/web-bi-logger/dist/src/logger';

export const getBiLogger = (
  biLoggerDefaults: Record<string, string | number | undefined>,
  webBiLogger: Factory,
) =>
  webBiLogger
    .updateDefaults({
      sessionId: 'exampleStr',
      ...biLoggerDefaults,
    })
    .logger();
