import { POP_UP_LINK, NO_PAYMENTS_POP_UP_LINK } from './constants';

export const getUpgradeAndTestPaymentModel = (instance: string): string =>
  `${POP_UP_LINK}?instance=${instance}&referralInfo=payApiPaymentsPopup&startLoadTime=${Date.now()}`;

export const getNoPaymentsPopupUrl = ({
  instance,
  referralInfo,
  startLoadTime,
}: {
  instance: string;
  referralInfo?: string;
  startLoadTime?: number;
}): string =>
  `${NO_PAYMENTS_POP_UP_LINK}?instance=${instance}&referralInfo=${referralInfo}&startLoadTime=${startLoadTime}`;
