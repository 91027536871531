import { BaseHttpService } from '../BaseHttpService';
import { PREMIUM_STATUS_API_PATH } from './constants';

export class PremiumStatusService extends BaseHttpService {
  async getIsPremium() {
    try {
      const { data } = await this.httpClient.get<{
        canAcceptOnlinePayments: boolean;
      }>(PREMIUM_STATUS_API_PATH, this.headers);

      return data.canAcceptOnlinePayments;
    } catch (error) {
      return true;
    }
  }
}
